
import {
    computed, defineComponent, PropType, reactive,
} from 'vue';
import Location from '@/domain/Location';
import SortStation from '@/domain/SortStation';
import SortStationService from '@/services/SortStationService';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { TableDefinition } from '@/types';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BForm from '@/components/bootstrap-library/BForm.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BFormCheckbox from '@/components/bootstrap-library/BFormCheckbox.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type SortStationTableData = {
    id: number;
    name: string;
    isTeamSort: boolean;
    locationId: number;
}

type State = {
    sortStationFrom: SortStation | null;
    saving: boolean;
}

export default defineComponent({
    name: 'sort-stations',
    components: {
        BSpinner,
        BCol,
        BRow,
        BFormCheckbox,
        BFormInput,
        BForm,
        BDropdown,
        BDropdownItem,
        BModal,
        BButton,
        BAdvancedTable,
    },
    props: {
        location: {
            type: Object as PropType<Location>,
            required: true,
        },
    },
    setup(props) {
        const sortStationService = new SortStationService();

        const { validateForm, validationResult, clearResults } = useValidator<SortStation>('sort-station');

        const state = reactive<State>({
            sortStationFrom: null,
            saving: false,
        });

        function mapToTableData(sortStation: Array<SortStation>): Array<SortStationTableData> {
            return sortStation.map((s) => ({
                id: s.id,
                name: s.name,
                isTeamSort: s.isTeamSort,
                locationId: s.locationId,
            }));
        }

        async function openAdd() {
            clearResults();
            state.sortStationFrom = new SortStation({
                id: 0,
                name: '',
                isTeamSort: false,
                locationId: props.location.id,
            });
        }

        const table = computed(
            (): TableDefinition<SortStationTableData> => ({
                items: mapToTableData(props.location.sortStations),
                key: 'sortStations',
                name: getTitleCaseTranslation('core.domain.sortStations'),
                emptyState: {
                    title: getTitleCaseTranslation('core.common.noSortStations'),
                    message: getTranslation('core.common.clickAddToStartAddingSortStations'),
                    icon: 'project-diagram',
                    button: {
                        onClick: openAdd,
                        text: getTitleCaseTranslation('core.button.add'),
                        disabled: false,
                    },
                },
                columnDefinition: [
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                        searchable: true,
                    },
                    {
                        key: 'isTeamSort',
                        label: getTitleCaseTranslation('core.domain.teamSort'),
                    },
                ],
            }),
        );

        async function openEdit(data: SortStationTableData) {
            clearResults();
            state.sortStationFrom = new SortStation(data);
        }

        async function save() {
            if (state.sortStationFrom) {
                validateForm(state.sortStationFrom);
                if (!validationResult.isValid) return;

                state.saving = true;
                if (state.sortStationFrom.id) {
                    const response = await sortStationService.updateSortStation(state.sortStationFrom);
                    if (response) {
                        props.location.sortStations = props.location.sortStations.map((s) => {
                            if (s.id === state.sortStationFrom?.id) {
                                return state.sortStationFrom;
                            }
                            return s;
                        });
                        state.sortStationFrom = null;
                    }
                } else {
                    const response = await sortStationService.createSortStation(state.sortStationFrom);
                    if (response) {
                        props.location.sortStations.push(response);
                        state.sortStationFrom = null;
                    }
                }
                state.saving = false;
            }
        }

        async function disable(data: SortStationTableData) {
            const response = await sortStationService.deleteSortStation(data);
            if (response) props.location.sortStations = props.location.sortStations.filter((s) => s.id !== data.id);
        }

        return {
            table,
            state,
            openAdd,
            openEdit,
            save,
            disable,
            getTitleCaseTranslation,
            validationResult,
        };
    },
});
