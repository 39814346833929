import axios, { AxiosResponse } from 'axios';
import { API_SERVICE_URL } from '@/config/env';
import { DataListAccessResponseClass, DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import LocationAddress from '@/domain/LocationAddress';

export default class LocationAddressApiService {
    public async getLocationAddress(id: number): Promise<DataAccessResponse<LocationAddress>> {
        const response: AxiosResponse<DataAccessResponse<LocationAddress>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/locationAddress/${id}`,
        });
        return new DataAccessResponseClass<LocationAddress>(response, LocationAddress).response;
    }

    public async getAllLocationAddressesByLocation(locationId: number): Promise<DataAccessResponse<Array<LocationAddress>>> {
        const response: AxiosResponse<DataAccessResponse<Array<LocationAddress>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/locationAddress/location/${locationId}`,
        });
        return new DataListAccessResponseClass<LocationAddress>(response, LocationAddress).response;
    }

    public async addNewLocationAddress(locationAddress: LocationAddress): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/locationAddress`,
            data: locationAddress,
        });
        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateLocationAddress(locationAddress: LocationAddress): Promise<DataAccessResponse<LocationAddress>> {
        const response: AxiosResponse<DataAccessResponse<LocationAddress>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/locationAddress`,
            data: locationAddress,
        });
        return new DataAccessResponseClass<LocationAddress>(response, LocationAddress).response;
    }

    public async deleteLocationAddress(id: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'DELETE',
            url: `${API_SERVICE_URL}/locationAddress/${id}`,
        });
        return new DataAccessResponseClass<number>(response).response;
    }
}
