
import {
    computed, defineComponent, onBeforeMount, PropType, reactive, watch,
} from 'vue';
import Location from '@/domain/Location';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import { StaticLocationType } from '@/domain/LocationType';
import { TableDefinition } from '@/types';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import RouteConfigService from '@/services/RouteConfigService';
import RouteConfigTableColumnDataDTO from '@/modules/master-data/dtos/RouteConfigTableColumnDataDTO';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import BMultiSelect from '@/components/BMultiSelect.vue';
import Item from '@/domain/Item';
import ItemService from '@/services/ItemService';
import Popover from '@/components/bootstrap-library/Popover.vue';
import Permissions from '@/services/permissions/Permissions';
import TrailerType from '@/domain/TrailerType';
import { CoreStore } from '@/store/CoreStore';
import BTab from '@/components/bootstrap-library/BTab.vue';
import BTabs from '@/components/bootstrap-library/BTabs.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import DropdownAutocompleteSingleSelect from '@/components/dropdown/DropdownAutocompleteSingleSelect.vue';
import DurationInput from '@/components/DurationInput.vue';
import useDurationCalculator from '@/composable/useDurationCalculator';
import BCol from '@/components/bootstrap-library/BCol.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import BBadge from '@/components/bootstrap-library/BBadge.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import FormMultiSelect from '@/components/FormMultiSelect.vue';
import InventoryCategory from '@/domain/InventoryCategory';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import useValidator from '@/validation/useValidator';
import ShippingLoadType from '@/domain/ShippingLoadType';
import BRadioButtonGroup, { RadioButtonOption } from '@/components/bootstrap-library/BRadioButtonGroup.vue';
import Carrier from '@/domain/Carrier';

type State = {
    routeConfigs: Array<LocationRouteConfig>;
    trailerTypes: Array<TrailerType>;
    preferredTrailerType: TrailerType;
    canMoveInventoryCategories: Array<InventoryCategory>;
    shipFromInventoryCategories: Array<InventoryCategory>;
    shippingLoadTypes: Array<ShippingLoadType>;
    carriers: Array<Carrier>;
    currentRouteConfig: LocationRouteConfig;
    showEditModal: boolean;
    allItems: Array<Item>;
    showAddModal: boolean;
    newRouteLocationSelected: Location | null;
    routeDirectionSelected: string;
};

export default defineComponent({
    name: 'location-ship-to',
    components: {
        BDropdown,
        BDropdownItem,
        BButton,
        BModal,
        BBadge,
        BRow,
        SelectInput,
        BCol,
        DurationInput,
        DropdownAutocompleteSingleSelect,
        Popover,
        BMultiSelect,
        BTab,
        BTabs,
        BAdvancedTable,
        FormMultiSelect,
        BRadioButtonGroup,
    },
    props: {
        location: {
            type: Object as PropType<Location>,
            required: true,
        },
        routeConfigs: {
            type: Array as PropType<Array<LocationRouteConfig>>,
            default: () => [],
            required: true,
        },
        readonly: Boolean,
    },
    emits: ['updateList'],

    setup(props, context) {
        const routeConfigService = new RouteConfigService();
        const itemService = new ItemService();
        const { locationStore } = masterDataStore.getInstance();
        const { convertToDHM } = useDurationCalculator();
        const { validateForm, validationResult } = useValidator<LocationRouteConfig>('location-route-config');
        const routeDirectionOptions: RadioButtonOption[] = [
            { text: getTitleCaseTranslation('core.domain.outbound'), value: 'outbound' },
            { text: getTitleCaseTranslation('core.domain.inbound'), value: 'inbound' },
        ];

        const state = reactive<State>({
            routeConfigs: props.routeConfigs.slice(),
            trailerTypes: CoreStore.getInstance().configStore.trailerTypes,
            preferredTrailerType: CoreStore.getInstance().configStore.preferredTrailerType,
            canMoveInventoryCategories: CoreStore.getInstance().inventoryCategoryStore.canMoveInventoryCategories,
            shipFromInventoryCategories: [],
            shippingLoadTypes: CoreStore.getInstance().shippingLoadTypeStore.shippingLoadTypes,
            carriers: CoreStore.getInstance().carrierStore.carriers,
            currentRouteConfig: new LocationRouteConfig(),
            showEditModal: false,
            allItems: [],
            showAddModal: false,
            newRouteLocationSelected: null,
            routeDirectionSelected: routeDirectionOptions[0].value,
        });

        const { confirm } = useDialogBox();

        async function getAllItems() {
            const response = await itemService.getAllItems();
            if (response.success) {
                state.allItems = response.items;
            }
        }

        onBeforeMount(async () => {
            await getAllItems();
        });

        watch(
            () => props.routeConfigs,
            (configs) => {
                state.routeConfigs = configs.slice();
            },
        );

        function IsRouteOutbound(): boolean {
            return state.routeDirectionSelected === 'outbound';
        }

        // will need to figure out what columns were going to use and map appropriately
        // ternarys are only in there because the db has bad data at the moment
        const locationTableData = computed(
            (): Array<RouteConfigTableColumnDataDTO> => state.routeConfigs.filter((route) => {
                if ((IsRouteOutbound() && route.fromLocation.id === props.location.id)
                    || (!IsRouteOutbound() && route.toLocation.id === props.location.id)) {
                    return true;
                }
                return false;
            }).map(
                (data: LocationRouteConfig) => ({
                    id: data.id,
                    toLocationId: data.toLocationId ?? -1,
                    toLocationName: data.toLocation.numberAndName,
                    fromLocationId: data.fromLocationId ?? -1,
                    fromLocationName: data.fromLocation.numberAndName,
                    city: data.toLocation.receivingAddress?.city ?? '-',
                    state: data.toLocation.receivingAddress?.state ?? '-',
                    country: data.toLocation.receivingAddress?.country ?? '-',
                    itemCount: data.itemCount,
                    transitTime: data.transitTime,
                    preferredTrailerTypeId: data.preferredTrailerTypeId,
                    shipFromInventoryCategories: data.shipFromInventoryCategories,
                    shippingLoadTypeId: data.shippingLoadTypeId,
                    carrierId: data.defaultCarrierId,
                    minTrailerPercent: data.minTrailerPercent,
                    maxLTLDayRange: data.maxLTLDayRange,
                } as RouteConfigTableColumnDataDTO),
            ),
        );

        const table = computed(
            (): TableDefinition<RouteConfigTableColumnDataDTO> => ({
                items: locationTableData.value,
                key: 'route-config-table',
                name: getTitleCaseTranslation('core.domain.routeConfiguration'),
                columnDefinition: [
                    {
                        key: 'toLocationName',
                        label: getTitleCaseTranslation('core.domain.toLocation'),
                        searchable: true,
                    },
                    {
                        key: 'fromLocationName',
                        label: getTitleCaseTranslation('core.domain.fromLocation'),
                        searchable: true,
                    },
                    {
                        key: 'city',
                        label: getTitleCaseTranslation('core.domain.city'),
                        searchable: true,
                    },
                    {
                        key: 'state',
                        label: getTitleCaseTranslation('core.domain.state'),
                        searchable: true,
                    },
                    {
                        key: 'country',
                        label: getTitleCaseTranslation('core.domain.country'),
                        searchable: true,
                    },
                    {
                        key: 'transitTime',
                        label: getTitleCaseTranslation('core.domain.transitTime'),
                    },
                    {
                        key: 'itemCount',
                        label: getTitleCaseTranslation('core.domain.items'),
                    },
                ],
            }),
        );

        const validLocations = computed(() => locationStore.locations.filter((loc) => {
            if (IsRouteOutbound()
                    && props.location.id !== loc.id
                    && loc.type.staticTypeIdentifier !== StaticLocationType.BuiltIn
                    && !locationTableData.value.map((d) => d.toLocationId).includes(loc.id)
            ) {
                return true;
            }
            if (!IsRouteOutbound()
                    && props.location.id !== loc.id
                    && loc.type.staticTypeIdentifier !== StaticLocationType.BuiltIn
                    && !locationTableData.value.map((d) => d.fromLocationId).includes(loc.id)
            ) {
                return true;
            }

            return false;
        }));

        function closeRouteModal() {
            state.showAddModal = false;
            state.newRouteLocationSelected = null;
        }

        function openRouteModal() {
            state.showAddModal = true;
            state.newRouteLocationSelected = null;
        }

        async function deleteRoute(route: RouteConfigTableColumnDataDTO) {
            const response = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.deletingARouteCannotBeUndone'),
            });
            if (!response) {
                return;
            }

            const deleted = await routeConfigService.deleteShippingRoute(route.id);
            if (deleted) {
                state.routeConfigs = state.routeConfigs.filter((r) => r.id !== route.id);
                context.emit('updateList', state.routeConfigs);
            }
        }

        function onSelectNewRoute(value: Location) {
            state.newRouteLocationSelected = value;
        }

        function changeShippingMethod(value: ShippingLoadType) {
            state.currentRouteConfig.shippingLoadTypeId = value?.id;
            state.currentRouteConfig.isLTL = value?.allowMaxLTLDayRange;
            if (!value) {
                state.currentRouteConfig.minTrailerPercent = undefined;
                state.currentRouteConfig.maxLTLDayRange = undefined;
            } else if (!value.allowMaxLTLDayRange) {
                state.currentRouteConfig.maxLTLDayRange = undefined;
            }
        }

        function changeDefaultCarrier(value: Carrier) {
            state.currentRouteConfig.defaultCarrierId = value?.id;
        }

        function changePreferredTrailerType(value: TrailerType) {
            state.currentRouteConfig.preferredTrailerTypeId = value.id;
        }

        function openEditRouteModal(routeId: number) {
            const routeConfig = state.routeConfigs.find((route) => route.id === routeId);

            if (routeConfig) {
                const shippingLoadType = state.shippingLoadTypes.find((x) => x.id === routeConfig.shippingLoadTypeId);
                routeConfig.isLTL = shippingLoadType?.allowMaxLTLDayRange ?? false;
                state.currentRouteConfig = routeConfig;
                state.showEditModal = true;
                if (routeConfig.defaultCarrierId) {
                    state.currentRouteConfig.defaultCarrierId = routeConfig.defaultCarrierId;
                }
            }
        }

        async function addRoute() {
            if (state.newRouteLocationSelected) {
                const fromLocId = IsRouteOutbound() ? props.location.id : state.newRouteLocationSelected.id;
                const toLoc = IsRouteOutbound() ? state.newRouteLocationSelected : props.location;
                const response = await routeConfigService.addRoute(fromLocId, toLoc);

                if (response) {
                    state.routeConfigs.push(response);
                    context.emit('updateList', state.routeConfigs);
                    closeRouteModal();
                    openEditRouteModal(response.id);
                }
            }
        }

        function isItemNew(item: Item): boolean {
            const foundItem = state.currentRouteConfig.items.find((existingItem) => existingItem.id === item.id);
            return !foundItem;
        }

        function updateRouteConfigById(routeId: number, action: (x: LocationRouteConfig) => any) {
            state.routeConfigs.forEach((route) => {
                if (route.id === routeId) {
                    action(route);
                }
            });
        }

        async function updateRoute() {
            validateForm(state.currentRouteConfig);

            if (validationResult.isValid) {
                if (!(await routeConfigService.updateRouteDetails(state.currentRouteConfig))) {
                    return;
                }

                const response = await routeConfigService.updateItemList(state.currentRouteConfig.id, state.currentRouteConfig.items);
                if (response.success) {
                    updateRouteConfigById(state.currentRouteConfig.id, (x) => {
                        x.itemCount = response.itemCount;
                    });
                }
                context.emit('updateList', state.routeConfigs);
                state.showEditModal = false;
            }
        }

        return {
            table,
            validLocations,
            onSelectNewRoute,
            updateRoute,
            openEditRouteModal,
            addRoute,
            closeRouteModal,
            openRouteModal,
            isItemNew,
            Permissions,
            deleteRoute,
            changeShippingMethod,
            changeDefaultCarrier,
            changePreferredTrailerType,
            convertToDHM,
            getTitleCaseTranslation,
            getTranslation,
            validationResult,
            state,
            routeDirectionOptions,
        };
    },
});
