import { useNotification } from '@/composable/useNotifications';
import LocationAddressApiService from '@/services/api/LocationAddressApiService';
import LocationAddress from '@/domain/LocationAddress';
import { getTitleCaseTranslation } from './TranslationService';

export default class LocationAddressService {
    private service: LocationAddressApiService;

    private notification = useNotification();

    constructor() {
        this.service = new LocationAddressApiService();
    }

    public async getLocationAddress(id: number): Promise<LocationAddress | null> {
        const response = await this.service.getLocationAddress(id);
        return response.success ? response.data : null;
    }

    public async getAllLocationAddressesByLocation(locationId: number): Promise<Array<LocationAddress> | null> {
        const response = await this.service.getAllLocationAddressesByLocation(locationId);
        return response.success ? response.data : null;
    }

    public async addNewLocationAddress(locationAddress: LocationAddress): Promise<LocationAddress | null> {
        const response = await this.service.addNewLocationAddress(locationAddress);
        if (!response.success) {
            return null;
        }
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${locationAddress.displayName}`);
        return new LocationAddress({
            ...locationAddress,
            id: response.data,
        });
    }

    public async updateLocationAddress(locationAddress: LocationAddress): Promise<boolean> {
        const response = await this.service.updateLocationAddress(locationAddress);
        if (response.success) {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${locationAddress.displayName}`);
        }
        return response.success;
    }

    public async deleteLocationAddress(locationAddress: LocationAddress): Promise<boolean> {
        const response = await this.service.deleteLocationAddress(locationAddress.id);
        if (response.success) {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.deleted')} ${locationAddress.displayName}`);
        }
        return response.success;
    }
}
