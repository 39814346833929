
import { defineComponent } from 'vue';
import useDurationCalculator from '@/composable/useDurationCalculator';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'duration-input',
    components: { BRow, BCol, BFormInput },
    props: {
        modelValue: { type: Number, default: 0 },
        label: { type: String, default: undefined },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const {
            convertToDHM,
            changeDays,
            changeHours,
            changeMinutes,
        } = useDurationCalculator();

        function handleChangeDays(days: number) {
            context.emit('update:modelValue', changeDays(props.modelValue, days));
        }

        function handleChangeHours(hours: number) {
            context.emit('update:modelValue', changeHours(props.modelValue, hours));
        }

        function handleChangeMinutes(minutes: number) {
            context.emit('update:modelValue', changeMinutes(props.modelValue, minutes));
        }

        return {
            convertToDHM,
            getTitleCaseTranslation,
            handleChangeDays,
            handleChangeHours,
            handleChangeMinutes,
        };
    },
});
