import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ship-to-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_places_autocomplete = _resolveComponent("places-autocomplete")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.addressModal.show,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.addressModal.show) = $event)),
      title: _ctx.addressModal.title,
      centered: "",
      size: "xl"
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          disabled: _ctx.state.saving,
          onClick: _ctx.saveAddress
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
            (_ctx.state.saving)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.displayName,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.addressModal.address.displayName) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.displayName'),
              md: "4",
              autofocus: "",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.displayName,
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly", "error"]),
            _createVNode(_component_select_input, {
              model: _ctx.addressModal.address.type,
              options: _ctx.state.addressTypes,
              label: _ctx.getTitleCaseTranslation('core.domain.type'),
              md: "4",
              text: "text",
              value: "value",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.type,
              onChange: _cache[1] || (_cache[1] = (value) => _ctx.changeType(value))
            }, null, 8, ["model", "options", "label", "readonly", "error"])
          ]),
          _: 1
        }),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.emailAddress,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.addressModal.address.emailAddress) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.email'),
              md: "4",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.emailAddress,
              placeholder: _ctx.getTitleCaseTranslation('core.common.contactEmails'),
              class: "form-col",
              "more-info": _ctx.getTranslation('core.common.emailWillBeUsedForSendingNotificationsToTheLocation')
            }, null, 8, ["modelValue", "label", "readonly", "error", "placeholder", "more-info"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.contactName,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.addressModal.address.contactName) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.contact'),
              md: "4",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.contactName,
              placeholder: _ctx.getTitleCaseTranslation('core.common.contactName'),
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly", "error", "placeholder"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.phoneNumber,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.addressModal.address.phoneNumber) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.phoneNumber'),
              md: "4",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.phoneNumber,
              placeholder: _ctx.getTitleCaseTranslation('core.domain.phoneNumber'),
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly", "error", "placeholder"])
          ]),
          _: 1
        }),
        _createVNode(_component_b_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_places_autocomplete, {
              modelValue: _ctx.addressModal.address.streetAddress,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.addressModal.address.streetAddress) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.streetAddress'),
              md: "4",
              error: _ctx.validationResult.model.streetAddress,
              readonly: _ctx.readonly,
              class: "form-col",
              onOnSelect: _ctx.onSelectPlace
            }, null, 8, ["modelValue", "label", "error", "readonly", "onOnSelect"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.city,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.addressModal.address.city) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.city'),
              md: "4",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.city,
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly", "error"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.state,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.addressModal.address.state) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.state'),
              md: "4",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.state,
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly", "error"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.zip,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.addressModal.address.zip) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.zip'),
              md: "4",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.zip,
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly", "error"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.country,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.addressModal.address.country) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.country'),
              md: "4",
              readonly: _ctx.readonly,
              error: _ctx.validationResult.model.country,
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly", "error"]),
            _createVNode(_component_text_input, {
              modelValue: _ctx.addressModal.address.timeZone,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addressModal.address.timeZone) = $event)),
              label: _ctx.getTitleCaseTranslation('core.domain.timeZone'),
              readonly: _ctx.readonly,
              disabled: true,
              md: "4",
              class: "form-col"
            }, null, 8, ["modelValue", "label", "readonly"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_b_advanced_table, {
      "table-array": [_ctx.table],
      "allow-overflow": "",
      "sticky-header": "500px",
      style: {"margin":"20px","font-size":"13px"},
      "dynamic-columns": ""
    }, {
      toprow: _withCtx(() => [
        _createVNode(_component_b_button, {
          size: "sm",
          variant: "tertiary",
          disabled: _ctx.readonly || !_ctx.canAdd,
          "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ?
                        _ctx.getTranslation('core.common.aShippingAndReceivingAddressAlreadyExists') : _ctx.getTranslation('core.common.youDoNotHavePermissionToAddAddresses'),
          onClick: _ctx.openAddressModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addAddress')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "disabled-message", "onClick"])
      ]),
      action: _withCtx((row) => [
        _createVNode(_component_b_dropdown, {
          "no-caret": "",
          "close-on-click": ""
        }, {
          "button-content": _withCtx(() => [
            _createVNode(_component_faicon, { icon: "ellipsis-h" })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_b_dropdown_item, {
              disabled: _ctx.readonly,
              "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? null : _ctx.getTranslation('core.common.youDoNotHavePermissionToEditAddresses'),
              onClick: () => _ctx.openAddressModal(row.item)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "disabled-message", "onClick"]),
            _createVNode(_component_b_dropdown_item, {
              disabled: _ctx.readonly,
              "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? null : _ctx.getTranslation('core.common.youDoNotHavePermissionToDeleteAddresses'),
              onClick: () => _ctx.deleteAddress(row.item)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.delete')), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "disabled-message", "onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["table-array"])
  ]))
}