
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import Location from '@/domain/Location';
import LocationAddress from '@/domain/LocationAddress';
import LocationService from '@/services/LocationService';
import OrbisCard from '@/components/OrbisCard.vue';
import LocationForm from '@/modules/master-data/views/locations/components/LocationForm.vue';
import LocationAddresses from '@/modules/master-data/views/locations/components/LocationAddresses.vue';
import LocationShipTo from '@/modules/master-data/views/locations/components/LocationShipTo.vue';
import LocationPreferredServiceCenters from '@/modules/master-data/views/locations/components/LocationPreferredServiceCenters.vue';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import RouteConfigService from '@/services/RouteConfigService';
import LocationRouteConfig from '@/domain/LocationRouteConfig';
import router from '@/router';
import useValidator from '@/validation/useValidator';
import BTab from '@/components/bootstrap-library/BTab.vue';
import BTabs from '@/components/bootstrap-library/BTabs.vue';
import Screen from '@/components/layout/Screen.vue';
import LocationDock from '@/modules/master-data/views/locations/components/LocationDock.vue';
import Dock from '@/domain/Dock';
import DockType from '@/domain/DockType';
import DockService from '@/services/DockService';
import SortStations from '@/modules/master-data/views/locations/components/SortStations.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import Equipment from '@/domain/Equipment';
import LocationDailyUsageOverride from '@/modules/master-data/views/locations/components/LocationDailyUsageOverride.vue';
import EquipmentService from '@/services/EquipmentService';
import LocationFloorLocation from '@/modules/master-data/views/locations/components/LocationFloorLocation.vue';
import FloorLocation from '@/domain/FloorLocation';
import FloorLocationService from '@/services/FloorLocationService';

type State = {
    location: Location;
    loading: boolean;
    routeConfigs: Array<LocationRouteConfig>;
    docks: Array<Dock>;
    dockTypes: Array<DockType>;
    equipment?: Array<Equipment>;
    allLocations: Array<Location>;
    floorLocations: Array<FloorLocation>;
};

export default defineComponent({
    name: 'edit-location-screen',
    components: {
        LocationFloorLocation,
        SortStations,
        Screen,
        LocationPreferredServiceCenters,
        LocationShipTo,
        LocationForm,
        LocationAddresses,
        OrbisCard,
        BTabs,
        BTab,
        LocationDock,
        LocationDailyUsageOverride,
    },
    props: {
        locationId: {
            type: String,
            required: true,
        },
        edit: Boolean,
    },
    setup(props) {
        const equipmentService = new EquipmentService();
        const locationService = new LocationService();
        const routeConfigService = new RouteConfigService();
        const { confirm } = useDialogBox();
        const dockService = new DockService();
        const floorLocationService = new FloorLocationService();

        const { validateForm, validationResult, clearResults } = useValidator<Location>('update-location');

        const state = reactive<State>({
            location: new Location(),
            loading: true,
            routeConfigs: [],
            docks: [],
            dockTypes: [],
            allLocations: [],
            floorLocations: [],
        });

        const readonly = computed(() => router.currentRoute.value.name !== MasterDataRouteTypes.LOCATION.EDIT);

        const navbarTitle = computed(() => `${state.location.name} - ${state.location.type.description}`);

        async function getLocationById(): Promise<Location | null> {
            const locationId = parseInt(props.locationId, 10);
            return locationService.getLocationById(locationId);
        }

        async function setRouteConfigsForLocation(locationId: number) {
            state.routeConfigs = await routeConfigService.getAllRouteConfigsByLocation(locationId);
        }

        async function setDocksForLocation(locationId: number) {
            state.docks = await dockService.getAllDocksByLocationId(locationId);
        }

        async function setDockTypes() {
            state.dockTypes = await dockService.getDockTypes();
        }

        async function setAllLocations() {
            const locations = await locationService.getAllLocations();
            state.allLocations = locations.locations;
        }

        async function setFloorLocations() {
            const response = await floorLocationService.getFloorLocationsForLocation(state.location);
            state.floorLocations = response.floorLocations;
        }

        async function initLocation() {
            state.loading = true;
            await setDockTypes();
            const response = await getLocationById();
            if (response) {
                state.location = response;

                await Promise.all([
                    setRouteConfigsForLocation(response.id),
                    setDocksForLocation(response.id),
                    setAllLocations(),
                    setFloorLocations(),
                ]);

                if (state.location.isServiceCenter) {
                    const equipmentResp = await equipmentService.getAllEquipment(state.location.id, true);

                    if (equipmentResp.success) {
                        state.equipment = equipmentResp.equipment;
                    }
                }
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            clearResults();
            await initLocation();
        });

        async function saveLocation() {
            validateForm(state.location);
            if (validationResult.isValid) {
                let confirmSave = true;

                if (state.equipment && state.location.maxLoadPerTimeslot && state.location.maxLoadPerTimeslot > state.equipment.length) {
                    confirmSave = await confirm({
                        message: getTranslation('core.common.areYouSureMaxLoadPerTimeslot'),
                        okButton: getTitleCaseTranslation('core.common.yes'),
                        title: getTranslation('core.common.areYouSure'),
                    });
                }

                if (confirmSave) {
                    await locationService.updateLocation(state.location);
                }
            }
        }

        function goToLocationList() {
            router.push({ name: MasterDataRouteTypes.LOCATION.LIST });
        }

        function updateAddressList(list: Array<LocationAddress>) {
            state.location.locationAddresses = list;
        }

        function updateRouteConfigList(list: Array<LocationRouteConfig>) {
            state.routeConfigs = list;
        }

        function updateDocksForLocation(list: Array<Dock>) {
            state.docks = list;
        }

        function updateFloorLocationsForLocation(list: Array<FloorLocation>) {
            state.floorLocations = list;
        }

        return {
            state,
            goToLocationList,
            saveLocation,
            setRouteConfigsForLocation,
            readonly,
            validationResult,
            navbarTitle,
            updateAddressList,
            updateRouteConfigList,
            updateDocksForLocation,
            getTitleCaseTranslation,
            setDocksForLocation,
            updateFloorLocationsForLocation,
        };
    },
});
