import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cda15c2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "daily-usages-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_date_input = _resolveComponent("date-input")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_simple_date_popover = _resolveComponent("simple-date-popover")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showUsageEditModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.showUsageEditModal) = $event)),
      centered: "",
      title: _ctx.getTitleCaseTranslation('core.domain.averageDailyUsageOverride'),
      onHidden: _ctx.closeOverrideModal,
      onCancel: _ctx.closeOverrideModal
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _ctx.saveOverride
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.usageToEdit.override,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.usageToEdit.override) = $event)),
                  type: "number",
                  label: _ctx.getTitleCaseTranslation('core.domain.quantity'),
                  "null-if-empty": ""
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            }),
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_date_input, {
                  modelValue: _ctx.state.usageToEdit.overrideExpiration,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.usageToEdit.overrideExpiration) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.expires'),
                  clearable: _ctx.state.usageToEdit.overrideExpiration != undefined,
                  "lower-limit": new Date()
                }, null, 8, ["modelValue", "label", "clearable", "lower-limit"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onHidden", "onCancel"]),
    _createVNode(_component_b_advanced_table, {
      "table-array": [_ctx.table],
      "sticky-header": "800px",
      style: {"margin":"20px","font-size":"13px"},
      loading: _ctx.state.loading
    }, {
      action: _withCtx((row) => [
        _createVNode(_component_b_button, {
          size: "sm",
          variant: "tertiary",
          onClick: () => _ctx.openOverrideModal(row.item)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      "cell(overrideExpiration)": _withCtx((row) => [
        (row.item.overrideExpiration)
          ? (_openBlock(), _createBlock(_component_simple_date_popover, {
              key: 0,
              date: row.item.overrideExpiration
            }, null, 8, ["date"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["table-array", "loading"])
  ]))
}