
import {
    computed, defineComponent, onBeforeMount, onBeforeUnmount, PropType, reactive, watch,
} from 'vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import { Variant } from '@/types';

type State = {
    filterValue: string;
    activeSelectedList: Array<string>;
    activeUnselectedList: Array<string>;
    searchString: string;
    shiftHold: boolean;
    focus: string | null;
};

type PropObject = Record<string, string | number | null | undefined>;

export default defineComponent({
    name: 'multi-select',
    components: {},
    props: {
        modelValue: {
            type: Array as PropType<Array<PropObject>>,
            default: () => [],
        },
        data: {
            type: Array as PropType<Array<PropObject>>,
            required: true,
        },
        displayName: {
            type: String,
            required: true,
        },
        variant: {
            type: String as PropType<Variant>,
            default: () => 'primary',
        },
        cols: {
            type: String,
            default: () => '12',
        },
        selectedHeader: {
            type: String,
            default: () => 'Selected',
        },
        unselectedHeader: {
            type: String,
            default: () => 'Available',
        },
        filterProps: {
            type: Object as PropType<{ name: string; key: string }>,
            required: false,
            default: undefined,
        },
        filterOptions: {
            type: Array as PropType<Array<string>>,
            required: false,
            default: undefined,
        },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const state = reactive<State>({
            activeSelectedList: [],
            activeUnselectedList: [],
            searchString: '',
            shiftHold: false,
            focus: null,
            filterValue: '',
        });

        watch(
            () => state.searchString,
            () => {
                state.activeUnselectedList = [];
                state.focus = null;
            },
            { immediate: true },
        );

        function keyDown(e: KeyboardEvent) {
            if (e.key === 'Shift') {
                state.shiftHold = true;
            }
        }

        function keyUp(e: KeyboardEvent) {
            if (e.key === 'Shift') {
                state.shiftHold = false;
            }
        }

        onBeforeMount(() => {
            window.addEventListener('keydown', keyDown);
            window.addEventListener('keyup', keyUp);

            if (props.filterOptions && !props.filterProps) {
                // eslint-disable-next-line no-console
                console.warn('Cannot have FilterOptions without FilterProps!');
            }

            if (!props.filterOptions && props.filterProps) {
                // eslint-disable-next-line no-console
                console.warn('Cannot have FilterProps without FilterOptions!');
            }
        });

        onBeforeUnmount(() => {
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
        });

        const selectableFilterOptions = computed(
            (): Array<string> => {
                const options: Array<string> = [];
                options.push('All');
                props.filterOptions?.forEach((filterOption) => {
                    options.push(filterOption);
                });
                return options;
            },
        );

        function getDisplayName(propObject: PropObject): string {
            const displayName = propObject[props.displayName];
            // display name should always be a string, if its a number => convert it
            if (displayName) {
                return displayName.toString();
            }
            // eslint-disable-next-line no-console
            console.warn('MULTI-SELECT COMPONENT ERROR: ', 'No valid display name for object ', propObject);
            return '';
        }

        const unselectedData = computed(() => props.data.filter((d) => !props.modelValue.some((x) => getDisplayName(x) === getDisplayName(d))));

        const optionsFilteredData = computed(
            (): Array<PropObject> => unselectedData.value.filter((item) => {
                if (state.filterValue !== '' && state.filterValue !== 'All' && props.filterProps) {
                    return item[props.filterProps.key]?.toString() === state.filterValue;
                }
                return item;
            }),
        );

        const filteredData = computed(
            (): Array<PropObject> => optionsFilteredData.value.filter((item) => getDisplayName(item)
                .toLowerCase()
                .includes(state.searchString.toLowerCase())),
        );

        // need better names:  list is the list that the they select, dataList is all the possible items
        function selectAllInRange(displayNameInFocus: string, displayNameClicked: string, list: Array<string>, dataList: Array<PropObject>) {
            const indexFocus = dataList.findIndex((item) => getDisplayName(item) === displayNameInFocus);
            const indexClicked = dataList.findIndex((item) => getDisplayName(item) === displayNameClicked);

            let fromIndex = -1;
            let toIndex = -1;

            if (indexFocus > indexClicked) {
                fromIndex = indexClicked;
                toIndex = indexFocus;
            }

            if (indexClicked > indexFocus) {
                fromIndex = indexFocus;
                toIndex = indexClicked;
            }

            dataList.forEach((item, index) => {
                if (fromIndex <= index && toIndex >= index) {
                    const existingItem = list.find((data) => data === getDisplayName(item));
                    if (!existingItem) {
                        list.push(getDisplayName(item));
                    }
                }
            });

            state.focus = null;
        }

        function selectSelectedItem(item: PropObject) {
            const index = state.activeSelectedList.findIndex((active) => active === getDisplayName(item));

            if (state.focus && state.shiftHold) {
                selectAllInRange(state.focus, getDisplayName(item), state.activeSelectedList, props.modelValue);
            } else if (index > -1) {
                state.activeSelectedList.splice(index, 1);
                state.focus = null;
            } else {
                state.activeSelectedList.push(getDisplayName(item));
                state.focus = getDisplayName(item);
            }

            state.activeUnselectedList = [];
        }

        function selectUnselectedItem(item: PropObject) {
            const index = state.activeUnselectedList.findIndex((active) => active === getDisplayName(item));

            if (state.focus && state.shiftHold) {
                selectAllInRange(state.focus, getDisplayName(item), state.activeUnselectedList, filteredData.value);
            } else if (index > -1) {
                state.activeUnselectedList.splice(index, 1);
                state.focus = null;
            } else {
                state.activeUnselectedList.push(getDisplayName(item));
                state.focus = getDisplayName(item);
            }

            state.activeSelectedList = [];
        }

        function add() {
            const selected = [...props.modelValue];
            const newSelections = props.data.filter((d) => state.activeUnselectedList.includes(getDisplayName(d)));
            selected.unshift(...newSelections);
            state.activeUnselectedList = [];
            context.emit('update:modelValue', selected);
            state.focus = null;
        }

        function remove() {
            const selected = props.modelValue.filter((d) => !state.activeSelectedList.includes(getDisplayName(d)));
            state.activeSelectedList = [];
            context.emit('update:modelValue', selected);
            state.focus = null;
        }

        const activeClass = computed((): string => {
            switch (props.variant) {
            case 'primary':
                return 'theme-primary-outline';
            case 'secondary':
                return 'theme-btn-secondary';
            case 'tertiary':
                return 'theme-btn-tertiary';
            case 'success':
                return 'btn-success';
            case 'danger':
                return 'btn-danger';
            case 'warning':
                return 'btn-warning';
            case 'info':
                return 'btn-info';
            case 'light':
                return 'btn-light';
            case 'dark':
                return 'btn-dark';
            default:
                // eslint-disable-next-line no-console
                console.warn(`Bad variant of ${props.variant} in button`);
                return 'btn-primary';
            }
        });

        function setFilterValue(val: string) {
            state.searchString = '';
            state.filterValue = val;
        }

        function onChangeSearch(val: string) {
            state.searchString = val;
        }

        return {
            unselectedData,
            selectUnselectedItem,
            selectSelectedItem,
            state,
            add,
            remove,
            filteredData,
            activeClass,
            getDisplayName,
            setFilterValue,
            getTitleCaseTranslation,
            selectableFilterOptions,
            onChangeSearch,
        };
    },
});
