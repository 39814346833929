import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_input = _resolveComponent("select-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_orbis_card = _resolveComponent("orbis-card")!

  return (_openBlock(), _createBlock(_component_orbis_card, {
    title: _ctx.getTitleCaseTranslation('core.domain.preferredServiceCenters')
  }, {
    default: _withCtx(() => [
      (_ctx.state.show && !_ctx.loading)
        ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_select_input, {
                model: _ctx.modelValue.firstPreferredServiceCenter?.id,
                label: _ctx.getTitleCaseTranslation('core.domain.preferredServiceCenter'),
                options: _ctx.firstSelectOptions,
                text: "name",
                value: "id",
                readonly: _ctx.readonly,
                md: "3",
                cols: "12",
                error: _ctx.validationResult.model.firstPreferredServiceCenter,
                onChange: _cache[0] || (_cache[0] = (value) => _ctx.changePreferredServiceCenter(value, 'first'))
              }, null, 8, ["model", "label", "options", "readonly", "error"]),
              _createVNode(_component_select_input, {
                model: _ctx.modelValue.secondPreferredServiceCenter?.id,
                label: _ctx.getTitleCaseTranslation('core.domain.secondPreferredServiceCenter'),
                options: _ctx.secondSelectOptions,
                text: "name",
                value: "id",
                readonly: _ctx.readonly,
                md: "3",
                cols: "12",
                error: _ctx.validationResult.model.secondPreferredServiceCenter,
                disabled: _ctx.disableSecondPreferredServiceCenter,
                onChange: _cache[1] || (_cache[1] = (value) => _ctx.changePreferredServiceCenter(value, 'second'))
              }, null, 8, ["model", "label", "options", "readonly", "error", "disabled"]),
              _createVNode(_component_select_input, {
                model: _ctx.modelValue.thirdPreferredServiceCenter?.id,
                label: _ctx.getTitleCaseTranslation('core.domain.thirdPreferredServiceCenter'),
                options: _ctx.thirdSelectOptions,
                text: "name",
                value: "id",
                readonly: _ctx.readonly,
                md: "3",
                cols: "12",
                disabled: _ctx.disableThirdPreferredServiceCenter,
                error: _ctx.validationResult.model.thirdPreferredServiceCenter,
                onChange: _cache[2] || (_cache[2] = (value) => _ctx.changePreferredServiceCenter(value, 'third'))
              }, null, 8, ["model", "label", "options", "readonly", "disabled", "error"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title"]))
}