import InventoryApiService from '@/modules/master-data/services/api/InventoryApiService';
import { useNotification } from '@/composable/useNotifications';
import InventoryAverageDailyUsage from '../domain/InventoryAverageDailyUsage';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class InventoryService {
    private inventoryApiService: InventoryApiService;
    private notification = useNotification();

    constructor() {
        this.inventoryApiService = new InventoryApiService();
    }

    public async getAllAverageDailyUsages(locationId: number): Promise<Array<InventoryAverageDailyUsage>> {
        const response = await this.inventoryApiService.getAverageDailyUsagesForLocation(locationId);

        if (!response.success) {
            return [];
        }
        return response.data;
    }

    public async updateAverageDailyUsageOverride(averageDailyUsage: InventoryAverageDailyUsage): Promise<InventoryAverageDailyUsage | null> {
        const dto = averageDailyUsage.toUpdateOverrideDTO();
        const response = await this.inventoryApiService.updateAverageDailyUsageOverride(dto);
        if (response.success) {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.domain.averageDailyUsageOverride')} ${getTitleCaseTranslation('core.common.updated')}`);
            return response.data;
        }
        return null;
    }
}
