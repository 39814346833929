import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b786903"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "docks-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.dockModal.show,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dockModal.show) = $event)),
      centered: "",
      title: _ctx.pageTitle,
      onHidden: _ctx.closeDockModal
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          disabled: _ctx.state.saving,
          onClick: _ctx.saveDock
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
            (_ctx.state.saving)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.dockEdit.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.dockEdit.name) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.doorNumber'),
                  md: "4",
                  error: _ctx.validationResult.model.name
                }, null, 8, ["modelValue", "label", "error"]),
                _createVNode(_component_select_input, {
                  model: _ctx.state.dockEdit.typeId,
                  label: _ctx.getTitleCaseTranslation('core.domain.usageType'),
                  options: _ctx.dockTypes,
                  loading: _ctx.loading,
                  text: "description",
                  value: "id",
                  class: "form-col",
                  md: "4",
                  error: _ctx.validationResult.model.typeId,
                  onChange: _cache[1] || (_cache[1] = (value) => _ctx.setDockType(value))
                }, null, 8, ["model", "label", "options", "loading", "error"]),
                _createVNode(_component_checkbox_input, {
                  modelValue: _ctx.state.dockEdit.inService,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.dockEdit.inService) = $event)),
                  md: "2",
                  label: _ctx.getTitleCaseTranslation('core.domain.inService')
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onHidden"]),
    _createVNode(_component_b_advanced_table, {
      "table-array": [_ctx.table],
      "sticky-header": "800px",
      style: {"margin":"20px","font-size":"13px"},
      "dynamic-columns": ""
    }, {
      toprow: _withCtx(() => [
        _createVNode(_component_b_button, {
          size: "sm",
          variant: "tertiary",
          disabled: !_ctx.Permissions.ADMINISTRATION.canEditLocations() || _ctx.readonly || _ctx.loading,
          "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? null : _ctx.getTranslation('core.common.youDoNotHavePermissionToAddDockDoors'),
          onClick: _ctx.openAddDockModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addDock')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "disabled-message", "onClick"])
      ]),
      action: _withCtx((row) => [
        _createVNode(_component_b_button, {
          size: "sm",
          variant: "tertiary",
          disabled: !_ctx.Permissions.ADMINISTRATION.canEditLocations() || _ctx.readonly,
          "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? null : _ctx.getTranslation('core.common.youDoNotHavePermissionToEditDockDoors'),
          onClick: () => _ctx.openEditDockModal(row.item)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
          ]),
          _: 2
        }, 1032, ["disabled", "disabled-message", "onClick"])
      ]),
      _: 1
    }, 8, ["table-array"])
  ]))
}