import Item from '@/domain/Item';
import Location from '@/domain/Location';
import UpdateInventoryAverageDailyUsageOverrideDTO from '@/modules/master-data/dtos/UpdateInventoryAverageDailyUsageOverrideDTO';

export default class InventoryAverageDailyUsage {
    constructor(init?: Partial<InventoryAverageDailyUsage>) {
        Object.assign(this, init);
        this.overrideExpiration = init?.overrideExpiration ? new Date(init.overrideExpiration) : undefined;
    }

    locationId!: number;

    location!: Location;

    itemId!: number;

    item!: Item;

    amount!: number;

    override?: number;

    overrideExpiration?: Date;

    get itemName(): string {
        return this.item?.name || '';
    }

    toUpdateOverrideDTO(): UpdateInventoryAverageDailyUsageOverrideDTO {
        return new UpdateInventoryAverageDailyUsageOverrideDTO(this);
    }
}
