import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5286e104"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sort-station-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_checkbox = _resolveComponent("b-form-checkbox")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      title: !_ctx.state.sortStationFrom?.id ? _ctx.getTitleCaseTranslation('core.button.add') : _ctx.getTitleCaseTranslation('core.button.edit'),
      "model-value": !!_ctx.state.sortStationFrom,
      centered: "",
      "disable-escape-key": _ctx.state.saving,
      onHidden: _cache[2] || (_cache[2] = ($event: any) => (_ctx.state.sortStationFrom = null))
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          disabled: _ctx.state.saving,
          onClick: _ctx.save
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
            (_ctx.state.saving)
              ? (_openBlock(), _createBlock(_component_b_spinner, {
                  key: 0,
                  small: ""
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"])
      ]),
      default: _withCtx(() => [
        (_ctx.state.sortStationFrom)
          ? (_openBlock(), _createBlock(_component_b_form, {
              key: 0,
              onSubmit: _ctx.save
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, { cols: "8" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_input, {
                          modelValue: _ctx.state.sortStationFrom.name,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.sortStationFrom.name) = $event)),
                          label: _ctx.getTitleCaseTranslation('core.domain.name'),
                          autofocus: "",
                          error: _ctx.validationResult.model.name
                        }, null, 8, ["modelValue", "label", "error"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_col, {
                      cols: "4",
                      style: {"display":"flex"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_form_checkbox, {
                          modelValue: _ctx.state.sortStationFrom.isTeamSort,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.sortStationFrom.isTeamSort) = $event)),
                          label: _ctx.getTitleCaseTranslation('core.domain.teamSort'),
                          style: {"margin":"auto","padding-top":"20px"}
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onSubmit"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "model-value", "disable-escape-key"]),
    _createVNode(_component_b_advanced_table, {
      "table-array": [_ctx.table],
      "sticky-header": "calc(100vh - 253px)"
    }, {
      toprow: _withCtx(() => [
        _createVNode(_component_b_button, {
          size: "sm",
          variant: "tertiary",
          onClick: _ctx.openAdd
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addSortStation')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      action: _withCtx((row) => [
        _createVNode(_component_b_dropdown, {
          "no-caret": "",
          "close-on-click": ""
        }, {
          "button-content": _withCtx(() => [
            _createVNode(_component_faicon, { icon: "ellipsis-h" })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_b_dropdown_item, {
              size: "sm",
              onClick: () => _ctx.openEdit(row.item)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createVNode(_component_b_dropdown_item, {
              size: "sm",
              onClick: () => _ctx.disable(row.item)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.delete')), 1)
              ]),
              _: 2
            }, 1032, ["onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["table-array"])
  ]))
}