import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!

  return (_openBlock(), _createBlock(_component_b_row, null, {
    default: _withCtx(() => [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.label), 1))
        : _createCommentVNode("", true),
      _createVNode(_component_b_col, {
        cols: "12",
        md: "4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_input, {
            "model-value": _ctx.convertToDHM(_ctx.modelValue).days,
            type: "number",
            "append-group-text": _ctx.getTitleCaseTranslation('core.domain.days'),
            onChange: _ctx.handleChangeDays
          }, null, 8, ["model-value", "append-group-text", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_col, {
        cols: "12",
        md: "4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_input, {
            "model-value": _ctx.convertToDHM(_ctx.modelValue).hours,
            type: "number",
            "append-group-text": _ctx.getTitleCaseTranslation('core.domain.hours'),
            onChange: _ctx.handleChangeHours
          }, null, 8, ["model-value", "append-group-text", "onChange"])
        ]),
        _: 1
      }),
      _createVNode(_component_b_col, {
        cols: "12",
        md: "4"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_form_input, {
            "model-value": _ctx.convertToDHM(_ctx.modelValue).minutes,
            type: "number",
            "append-group-text": _ctx.getTitleCaseTranslation('core.domain.minutes'),
            onChange: _ctx.handleChangeMinutes
          }, null, 8, ["model-value", "append-group-text", "onChange"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}