import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3163b720"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multi-select-container" }
const _hoisted_2 = { class: "list-column" }
const _hoisted_3 = { class: "multi-select-head" }
const _hoisted_4 = { class: "multi-select-filter-row" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "item-slot" }
const _hoisted_7 = {
  key: 0,
  class: "empty-container"
}
const _hoisted_8 = {
  key: 0,
  class: "empty-content"
}
const _hoisted_9 = {
  key: 1,
  class: "empty-content"
}
const _hoisted_10 = { class: "gutter" }
const _hoisted_11 = { class: "list-column" }
const _hoisted_12 = { class: "multi-select-head" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "item-slot" }
const _hoisted_15 = {
  key: 0,
  class: "empty-container"
}
const _hoisted_16 = { class: "empty-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_form_select = _resolveComponent("b-form-select")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_button = _resolveComponent("b-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.unselectedHeader), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.filterOptions)
          ? (_openBlock(), _createBlock(_component_b_row, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { cols: "8" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      "model-value": _ctx.state.searchString,
                      placeholder: `${_ctx.getTitleCaseTranslation('core.common.search')}...`,
                      class: "search",
                      onInput: _ctx.onChangeSearch,
                      onChange: _ctx.onChangeSearch
                    }, null, 8, ["model-value", "placeholder", "onInput", "onChange"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_b_col, {
                  cols: "4",
                  class: "multi-select-filter-col"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_select, {
                      "model-value": "All",
                      options: _ctx.selectableFilterOptions,
                      onChange: _ctx.setFilterValue
                    }, null, 8, ["options", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(_component_b_row, { key: 1 }, {
              default: _withCtx(() => [
                _createVNode(_component_b_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_form_input, {
                      "model-value": _ctx.state.searchString,
                      placeholder: `${_ctx.getTitleCaseTranslation('core.common.search')}...`,
                      class: "search",
                      onInput: _ctx.onChangeSearch,
                      onChange: _ctx.onChangeSearch
                    }, null, 8, ["model-value", "placeholder", "onInput", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
      ]),
      _createVNode(_component_b_row, { class: "multi-select-row" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredData, (item, index) => {
            return (_openBlock(), _createBlock(_component_b_col, {
              key: `val_${index}`,
              class: "multi-select-col",
              cols: _ctx.cols
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([
                            'multi-select-list-item multi-select-list-item-action',
                            _ctx.state.activeUnselectedList.includes(_ctx.getDisplayName(item)) ? _ctx.activeClass : 'item-inactive',
                            { 'item-focus': _ctx.state.focus === _ctx.getDisplayName(item) },
                        ]),
                  onClick: () => _ctx.selectUnselectedItem(item)
                }, [
                  _renderSlot(_ctx.$slots, "list-item-unselected", { item: item }, () => [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.getDisplayName(item)), 1)
                  ], true)
                ], 10, _hoisted_5)
              ]),
              _: 2
            }, 1032, ["cols"]))
          }), 128)),
          (_ctx.filteredData.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                (_ctx.unselectedData.length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.selectOptions.noAvailableItems')), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_faicon, { icon: "search" }),
                      _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.selectOptions.noItemsInSearch')), 1)
                    ]))
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_b_button, {
        variant: "light",
        onClick: _ctx.add
      }, {
        default: _withCtx(() => [
          _createVNode(_component_faicon, { icon: "arrow-right" })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_b_button, {
        variant: "light",
        onClick: _ctx.remove
      }, {
        default: _withCtx(() => [
          _createVNode(_component_faicon, { icon: "arrow-left" })
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("h5", null, _toDisplayString(_ctx.selectedHeader), 1)
      ]),
      _createVNode(_component_b_row, { class: "multi-select-row" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modelValue, (item, index) => {
            return (_openBlock(), _createBlock(_component_b_col, {
              key: `val_${index}`,
              class: "multi-select-col",
              cols: _ctx.cols
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass([
                            'multi-select-list-item multi-select-list-item-action',
                            _ctx.state.activeSelectedList.includes(_ctx.getDisplayName(item)) ? _ctx.activeClass : 'item-inactive',
                            { 'item-focus': _ctx.state.focus === _ctx.getDisplayName(item) },
                        ]),
                  onClick: () => _ctx.selectSelectedItem(item)
                }, [
                  _renderSlot(_ctx.$slots, "list-item-selected", { item: item }, () => [
                    _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getDisplayName(item)), 1)
                  ], true)
                ], 10, _hoisted_13)
              ]),
              _: 2
            }, 1032, ["cols"]))
          }), 128)),
          (_ctx.modelValue.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.selectOptions.noItemsAdded')), 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      })
    ])
  ]))
}