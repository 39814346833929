import InventoryAverageDailyUsage from '@/modules/master-data/domain/InventoryAverageDailyUsage';

export default class UpdateInventoryAverageDailyUsageOverrideDTO {
    constructor(averageDailyUsage: InventoryAverageDailyUsage) {
        this.locationId = averageDailyUsage.locationId;
        this.itemId = averageDailyUsage.itemId;
        this.override = averageDailyUsage.override;
        this.overrideExpiration = averageDailyUsage.overrideExpiration;
    }

    locationId!: number;

    itemId!: number;

    override?: number;

    overrideExpiration?: Date;
}
