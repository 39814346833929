import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-611664c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ship-to-container" }
const _hoisted_2 = { class: "item-slot" }
const _hoisted_3 = { class: "nowrap-with-ellipse" }
const _hoisted_4 = { style: {"max-width":"250px","height":"375px"} }
const _hoisted_5 = { class: "item-slot" }
const _hoisted_6 = { class: "nowrap-with-ellipse" }
const _hoisted_7 = { style: {"display":"block","text-align":"left","margin":"5px 0 10px 0"} }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popover = _resolveComponent("popover")!
  const _component_b_badge = _resolveComponent("b-badge")!
  const _component_b_multi_select = _resolveComponent("b-multi-select")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_number_input = _resolveComponent("number-input")!
  const _component_duration_input = _resolveComponent("duration-input")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_dropdown_autocomplete_single_select = _resolveComponent("dropdown-autocomplete-single-select")!
  const _component_b_radio_button_group = _resolveComponent("b-radio-button-group")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showEditModal,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.showEditModal) = $event)),
      title: _ctx.getTitleCaseTranslation('core.domain.editRoute'),
      centered: "",
      size: "xl"
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _ctx.updateRoute
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_tabs, { class: "route-tabs" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.editItems')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_multi_select, {
                  modelValue: _ctx.state.currentRouteConfig.items,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.currentRouteConfig.items) = $event)),
                  data: _ctx.state.allItems,
                  "display-name": "name",
                  "selected-header": _ctx.getTitleCaseTranslation('core.selectOptions.selectedInRoute'),
                  "unselected-header": _ctx.getTitleCaseTranslation('core.selectOptions.availableToSelect'),
                  cols: "12",
                  style: {"height":"600px","max-height":"60vh","padding-top":"10px"}
                }, {
                  "list-item-unselected": _withCtx((row) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_popover, null, {
                        content: _withCtx(() => [
                          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getTranslation('core.common.thisIsWhereAllOfTheItemDetailsWillGo')), 1)
                        ]),
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(row.item.shortName) + " - " + _toDisplayString(row.item.name), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ])
                  ]),
                  "list-item-selected": _withCtx((row) => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(row.item.shortName) + " - " + _toDisplayString(row.item.name), 1),
                      (_ctx.isItemNew(row.item))
                        ? (_openBlock(), _createBlock(_component_b_badge, {
                            key: 0,
                            variant: "primary"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.getTranslation('core.common.new').toUpperCase()), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "data", "selected-header", "unselected-header"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.editDetails')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_select_input, {
                          options: _ctx.state.carriers,
                          model: _ctx.state.currentRouteConfig.defaultCarrierId,
                          "allow-empty-option": "",
                          text: "name",
                          value: "id",
                          class: "edit-details-labels",
                          label: _ctx.getTitleCaseTranslation('core.domain.defaultCarrier'),
                          onChange: _cache[1] || (_cache[1] = (value) => _ctx.changeDefaultCarrier(value))
                        }, null, 8, ["options", "model", "label"]),
                        _createVNode(_component_select_input, {
                          options: _ctx.state.shippingLoadTypes,
                          model: _ctx.state.currentRouteConfig.shippingLoadTypeId,
                          "allow-empty-option": "",
                          text: "description",
                          value: "id",
                          class: "edit-details-labels",
                          label: _ctx.getTitleCaseTranslation('core.domain.shippingMethod'),
                          onChange: _cache[2] || (_cache[2] = (value) => _ctx.changeShippingMethod(value))
                        }, null, 8, ["options", "model", "label"]),
                        _createVNode(_component_number_input, {
                          modelValue: _ctx.state.currentRouteConfig.minTrailerPercent,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.currentRouteConfig.minTrailerPercent) = $event)),
                          label: _ctx.getTitleCaseTranslation('core.domain.minTrailerPercent'),
                          disabled: !_ctx.state.currentRouteConfig.shippingLoadTypeId,
                          error: _ctx.validationResult?.model.minTrailerPercent,
                          type: "number",
                          min: 0,
                          max: 100
                        }, null, 8, ["modelValue", "label", "disabled", "error"]),
                        _createVNode(_component_number_input, {
                          modelValue: _ctx.state.currentRouteConfig.maxLTLDayRange,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.currentRouteConfig.maxLTLDayRange) = $event)),
                          disabled: !_ctx.state.currentRouteConfig.isLTL,
                          error: _ctx.validationResult?.model.maxLTLDayRange,
                          label: _ctx.getTranslation('core.domain.maxLTLDayRange'),
                          type: "number",
                          min: 0
                        }, null, 8, ["modelValue", "disabled", "error", "label"]),
                        _createVNode(_component_select_input, {
                          model: _ctx.state.currentRouteConfig.preferredTrailerTypeId,
                          options: _ctx.state.trailerTypes,
                          text: "name",
                          value: "id",
                          class: "edit-details-labels",
                          label: _ctx.getTitleCaseTranslation('core.domain.preferredTrailerType'),
                          onChange: _cache[5] || (_cache[5] = (value) => _ctx.changePreferredTrailerType(value))
                        }, null, 8, ["model", "options", "label"]),
                        _createVNode(_component_duration_input, {
                          modelValue: _ctx.state.currentRouteConfig.transitTime,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.state.currentRouteConfig.transitTime) = $event)),
                          label: _ctx.getTitleCaseTranslation('core.domain.transitTime')
                        }, null, 8, ["modelValue", "label"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_b_col, { cols: "6" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_form_multi_select, {
                          modelValue: _ctx.state.currentRouteConfig.shipFromInventoryCategories,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.state.currentRouteConfig.shipFromInventoryCategories) = $event)),
                          "search-by": "description",
                          data: _ctx.state.canMoveInventoryCategories,
                          error: _ctx.validationResult?.model.shipFromInventoryCategories,
                          label: _ctx.getTitleCaseTranslation('core.domain.shipFromInventoryCategories'),
                          class: "edit-details-labels"
                        }, null, 8, ["modelValue", "data", "error", "label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "title"]),
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showAddModal,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.showAddModal) = $event)),
      centered: "",
      title: _ctx.getTitleCaseTranslation('core.domain.addNewRoute'),
      onHidden: _ctx.closeRouteModal
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _ctx.addRoute
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_dropdown_autocomplete_single_select, {
          class: "location-autocomplete",
          placeholder: _ctx.getTitleCaseTranslation('core.common.searchLocation'),
          data: _ctx.validLocations,
          "search-by": "numberAndName",
          onOnSelect: _ctx.onSelectNewRoute
        }, null, 8, ["placeholder", "data", "onOnSelect"])
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onHidden"]),
    _createVNode(_component_b_advanced_table, {
      "table-array": [_ctx.table],
      "sticky-header": "800px",
      style: {"margin":"20px","font-size":"13px"},
      "dynamic-columns": ""
    }, {
      toprow: _withCtx(() => [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_b_radio_button_group, {
            modelValue: _ctx.state.routeDirectionSelected,
            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.routeDirectionSelected) = $event)),
            name: "inventory-view-switch",
            options: _ctx.routeDirectionOptions
          }, null, 8, ["modelValue", "options"]),
          _createVNode(_component_b_button, {
            style: {"text-align":"left","margin-top":"10px"},
            size: "sm",
            variant: "tertiary",
            disabled: _ctx.readonly,
            "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? undefined : _ctx.getTranslation('core.common.youDoNotHavePermissionToAddShippingRoutes'),
            onClick: _ctx.openRouteModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addRoute')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disabled-message", "onClick"])
        ])
      ]),
      action: _withCtx((row) => [
        _createVNode(_component_b_dropdown, {
          "no-caret": "",
          "close-on-click": ""
        }, {
          "button-content": _withCtx(() => [
            _createVNode(_component_faicon, { icon: "ellipsis-h" })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_b_dropdown_item, {
              disabled: _ctx.readonly,
              "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? undefined : _ctx.getTranslation('core.common.youDoNotHavePermissionToEditRouteItems'),
              onClick: () => _ctx.openEditRouteModal(row.item.id)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "disabled-message", "onClick"]),
            _createVNode(_component_b_dropdown_item, {
              disabled: _ctx.readonly,
              "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? undefined : _ctx.getTranslation('core.common.youDoNotHavePermissionToDeleteShippingRoute'),
              onClick: () => _ctx.deleteRoute(row.item)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.delete')), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "disabled-message", "onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      "cell(transitTime)": _withCtx((row) => [
        (row.item.transitTime)
          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.convertToDHM(row.item.transitTime).days) + "d " + _toDisplayString(_ctx.convertToDHM(row.item.transitTime).hours) + "h " + _toDisplayString(_ctx.convertToDHM(row.item.transitTime).minutes) + "m", 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_9, "-"))
      ]),
      _: 1
    }, 8, ["table-array"])
  ]))
}