export type DHM = {
    days: number;
    hours: number;
    minutes: number;
};

type UseDurationCalculator = {
    convertToDHM: (ms: number) => DHM
    changeDays: (ms: number, days: number) => number,
    changeHours: (ms: number, hours: number) => number,
    changeMinutes: (ms: number, minutes: number) => number,
};

export default function useDurationCalculator(): UseDurationCalculator {
    const conversionDay = 24 * 60 * 60 * 1000;
    const conversionHours = 60 * 60 * 1000;
    const conversionMinutes = 60 * 1000;

    function convertToDHM(ms: number): DHM {
        let d = Math.floor(ms / conversionDay);
        let h = Math.floor((ms - d * conversionDay) / conversionHours);
        let m = Math.round((ms - d * conversionDay - h * conversionHours) / conversionMinutes);
        if (m === 60) {
            h++;
            m = 0;
        }
        if (h === 24) {
            d++;
            h = 0;
        }
        return {
            days: d,
            hours: h,
            minutes: m,
        };
    }

    function convertToMs({ days, hours, minutes }: DHM): number {
        let ms = 0;
        ms += days * conversionDay;
        ms += hours * conversionHours;
        ms += minutes * conversionMinutes;
        return ms;
    }

    function isValid(ms: number, unitChange: number): boolean {
        if (unitChange < 0) return false;
        if (ms < 0) return false;
        return true;
    }

    function convertToValidNumber(ms: number): number {
        return Math.max(0, ms);
    }

    function changeDays(ms: number, days: number): number {
        if (!isValid(ms, days)) return convertToValidNumber(ms);
        const dhm = convertToDHM(ms);
        return convertToMs({
            days,
            hours: dhm.hours,
            minutes: dhm.minutes,
        });
    }

    function changeHours(ms: number, hours: number): number {
        if (!isValid(ms, hours)) return convertToValidNumber(ms);
        const dhm = convertToDHM(ms);
        return convertToMs({
            days: dhm.days,
            hours,
            minutes: dhm.minutes,
        });
    }

    function changeMinutes(ms: number, minutes: number): number {
        if (!isValid(ms, minutes)) return convertToValidNumber(ms);
        const dhm = convertToDHM(ms);
        return convertToMs({
            days: dhm.days,
            hours: dhm.hours,
            minutes,
        });
    }

    return {
        convertToDHM,
        changeDays,
        changeHours,
        changeMinutes,
    };
}
