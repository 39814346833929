
import {
    computed, defineComponent, PropType, reactive, onMounted,
} from 'vue';
import { TableDefinition } from '@/types';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import InventoryService from '@/modules/master-data/services/InventoryService';
import InventoryAverageDailyUsage from '@/modules/master-data/domain/InventoryAverageDailyUsage';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import Location from '@/domain/Location';

type State = {
    averageDailyUsages: Array<InventoryAverageDailyUsage>;
    usageToEdit: InventoryAverageDailyUsage;
    showUsageEditModal: boolean;
    loading: boolean;
};

export default defineComponent({
    name: 'location-daily-usage-override',
    components: {
        BAdvancedTable,
        SimpleDatePopover,
    },
    props: {
        location: {
            type: Object as PropType<Location>,
            required: true,
        },
    },
    emits: ['updateList'],

    setup(props) {
        const inventoryService = new InventoryService();

        const state = reactive<State>({
            averageDailyUsages: [],
            usageToEdit: new InventoryAverageDailyUsage(),
            showUsageEditModal: false,
            loading: false,
        });

        onMounted(async () => {
            state.loading = true;
            state.averageDailyUsages = await inventoryService.getAllAverageDailyUsages(props.location.id);
            state.loading = false;
        });

        const table = computed(
            (): TableDefinition<InventoryAverageDailyUsage> => ({
                items: state.averageDailyUsages,
                key: 'daily-usage-table',
                name: getTitleCaseTranslation('core.domain.planningItemOverride'),
                columnDefinition: [
                    {
                        key: 'itemName',
                        label: getTitleCaseTranslation('core.domain.item'),
                        searchable: false,
                    },
                    {
                        key: 'amount',
                        label: getTitleCaseTranslation('core.domain.averageDailyUsage'),
                        searchable: false,
                    },
                    {
                        key: 'override',
                        label: getTitleCaseTranslation('core.domain.overrideQuantity'),
                        searchable: false,
                    },
                    {
                        key: 'overrideExpiration',
                        label: getTitleCaseTranslation('core.domain.overrideExpirationDate'),
                        searchable: false,
                    },
                ],
            }),
        );

        function openOverrideModal(averageDailyUsage: InventoryAverageDailyUsage) {
            state.usageToEdit = new InventoryAverageDailyUsage(averageDailyUsage);
            state.showUsageEditModal = true;
        }

        function closeOverrideModal() {
            state.showUsageEditModal = false;
            state.loading = false;
        }

        async function saveOverride() {
            state.loading = true;
            const response = await inventoryService.updateAverageDailyUsageOverride(state.usageToEdit);
            if (response) {
                const index = state.averageDailyUsages.findIndex((usage) => usage.locationId === response.locationId && usage.itemId === response.itemId);
                state.averageDailyUsages[index] = response;
                closeOverrideModal();
            }
            state.loading = false;
        }

        return {
            state,
            table,
            getTitleCaseTranslation,
            getTranslation,
            openOverrideModal,
            closeOverrideModal,
            saveOverride,
        };
    },
});
