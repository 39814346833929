import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6749fb81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "locations-content container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "locations-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_location_form = _resolveComponent("location-form")!
  const _component_orbis_card = _resolveComponent("orbis-card")!
  const _component_location_addresses = _resolveComponent("location-addresses")!
  const _component_b_tab = _resolveComponent("b-tab")!
  const _component_location_ship_to = _resolveComponent("location-ship-to")!
  const _component_location_preferred_service_centers = _resolveComponent("location-preferred-service-centers")!
  const _component_location_dock = _resolveComponent("location-dock")!
  const _component_location_floor_location = _resolveComponent("location-floor-location")!
  const _component_sort_stations = _resolveComponent("sort-stations")!
  const _component_location_daily_usage_override = _resolveComponent("location-daily-usage-override")!
  const _component_b_tabs = _resolveComponent("b-tabs")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, {
    id: "edit-location-screen",
    title: _ctx.navbarTitle,
    full: "",
    "go-back": _ctx.goToLocationList
  }, {
    footer: _withCtx(() => [
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_b_button, {
              variant: "light",
              onClick: _ctx.goToLocationList
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_b_button, {
              class: "button-save",
              variant: "primary",
              onClick: _ctx.saveLocation
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_b_tabs, { class: "location-tabs" }, {
          default: _withCtx(() => [
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.details')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_orbis_card, {
                  title: _ctx.getTitleCaseTranslation('core.domain.details')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_location_form, {
                      modelValue: _ctx.state.location,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.location) = $event)),
                      loading: _ctx.state.loading,
                      readonly: _ctx.readonly,
                      "validation-result": _ctx.validationResult,
                      "all-locations": _ctx.state.allLocations
                    }, null, 8, ["modelValue", "loading", "readonly", "validation-result", "all-locations"])
                  ]),
                  _: 1
                }, 8, ["title"]),
                _createVNode(_component_location_addresses, {
                  id: "location-addresses",
                  loading: _ctx.state.loading,
                  location: _ctx.state.location,
                  readonly: _ctx.readonly,
                  onUpdateList: _ctx.updateAddressList
                }, null, 8, ["loading", "location", "readonly", "onUpdateList"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.routeConfiguration')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_location_ship_to, {
                  id: "ship-to",
                  loading: _ctx.state.loading,
                  location: _ctx.state.location,
                  "route-configs": _ctx.state.routeConfigs,
                  readonly: _ctx.readonly,
                  onUpdateList: _ctx.updateRouteConfigList
                }, null, 8, ["loading", "location", "route-configs", "readonly", "onUpdateList"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.preferredServiceCenters')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_location_preferred_service_centers, {
                  id: "other",
                  modelValue: _ctx.state.location,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.location) = $event)),
                  loading: _ctx.state.loading,
                  style: {"margin-bottom":"300px"},
                  "validation-result": _ctx.validationResult
                }, null, 8, ["modelValue", "loading", "validation-result"])
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.dockDoors')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_location_dock, {
                  id: "dock-doors",
                  loading: _ctx.state.loading,
                  docks: _ctx.state.docks,
                  "dock-types": _ctx.state.dockTypes,
                  readonly: _ctx.readonly,
                  location: _ctx.state.location,
                  onUpdateList: _ctx.updateDocksForLocation
                }, null, 8, ["loading", "docks", "dock-types", "readonly", "location", "onUpdateList"])
              ]),
              _: 1
            }, 8, ["title"]),
            (_ctx.state.location.isManagedWarehouse)
              ? (_openBlock(), _createBlock(_component_b_tab, {
                  key: 0,
                  title: _ctx.getTitleCaseTranslation('core.domain.floorLocation')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_location_floor_location, {
                      location: _ctx.state.location,
                      "floor-locations": _ctx.state.floorLocations,
                      onUpdateList: _ctx.updateFloorLocationsForLocation
                    }, null, 8, ["location", "floor-locations", "onUpdateList"])
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            (_ctx.state.location.isServiceCenter)
              ? (_openBlock(), _createBlock(_component_b_tab, {
                  key: 1,
                  title: _ctx.getTitleCaseTranslation('core.domain.sortStations')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_sort_stations, {
                      location: _ctx.state.location
                    }, null, 8, ["location"])
                  ]),
                  _: 1
                }, 8, ["title"]))
              : _createCommentVNode("", true),
            _createVNode(_component_b_tab, {
              title: _ctx.getTitleCaseTranslation('core.domain.planningItemOverride')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_location_daily_usage_override, {
                  id: "average-daily-usage",
                  location: _ctx.state.location
                }, null, 8, ["location"])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["title", "go-back"]))
}