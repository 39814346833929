
import {
    computed, defineComponent, PropType, reactive,
} from 'vue';
import Location from '@/domain/Location';
import { TableDefinition } from '@/types';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import Permissions from '@/services/permissions/Permissions';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import Dock from '@/domain/Dock';
import DockType from '@/domain/DockType';
import DockService from '@/services/DockService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import useValidator from '@/validation/useValidator';

type DockModal = {
    show: boolean;
};

type State = {
    dockEdit: Dock;
    saving: boolean;
};

export default defineComponent({
    name: 'location-dock',
    components: {
        BAdvancedTable,
        BSpinner,
    },
    props: {
        docks: {
            type: Array as PropType<Array<Dock>>,
            default: () => [],
            required: true,
        },
        location: {
            type: Object as PropType<Location>,
            required: true,
        },
        dockTypes: {
            type: Array as PropType<Array<DockType>>,
            default: () => [],
            required: true,
        },
        readonly: Boolean,
        loading: Boolean,
    },
    emits: ['updateList'],

    setup(props, context) {
        const dockService = new DockService();

        const state = reactive<State>({
            dockEdit: new Dock(),
            saving: false,
        });

        const dockModal = reactive<DockModal>({
            show: false,
        });

        const { validateForm, validationResult, clearResults } = useValidator<Dock>('dock');

        const { confirm } = useDialogBox();

        function getDockTypeById(dockTypeId: number) {
            const dockType = props.dockTypes.find((x) => x.id === dockTypeId);
            return dockType?.description || '';
        }

        const table = computed(
            (): TableDefinition<any> => ({
                items: props.docks.map((data: Dock) => ({
                    id: data.id,
                    name: data.name,
                    typeId: data.typeId,
                    inServiceText: getTitleCaseTranslation(data.inService ? 'core.common.yes' : 'core.common.no'),
                    dockTypeText: getDockTypeById(data.typeId),
                    locationId: data.locationId,
                })),
                key: 'dock-table',
                name: getTitleCaseTranslation('core.domain.dockDoors'),
                columnDefinition: [
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.doorNumber'),
                        searchable: true,
                    },
                    {
                        key: 'dockTypeText',
                        label: getTitleCaseTranslation('core.domain.usageType'),
                        searchable: true,
                    },
                    {
                        key: 'inServiceText',
                        label: getTitleCaseTranslation('core.domain.inService'),
                        searchable: true,
                    },
                ],
            }),
        );

        const pageTitle = computed(() => (state.dockEdit.id ? getTitleCaseTranslation('core.domain.editDock') : getTitleCaseTranslation('core.button.addDock')));

        function closeDockModal() {
            dockModal.show = false;
        }

        function openEditDockModal(dock: Dock) {
            clearResults();
            dockModal.show = true;
            state.dockEdit = new Dock(dock);
        }

        function openAddDockModal() {
            clearResults();
            state.dockEdit = new Dock();
            state.dockEdit.inService = true;
            state.dockEdit.locationId = props.location.id;
            dockModal.show = true;
        }

        async function saveDock() {
            state.saving = true;
            validateForm(state.dockEdit);
            if (validationResult.isValid) {
                if (state.dockEdit.isNew) {
                    const response = await dockService.addDock(state.dockEdit);
                    if (response.success) {
                        context.emit('updateList', [...props.docks, new Dock(response.dock)]);
                        closeDockModal();
                    }
                } else {
                    const response = await dockService.updateDock(state.dockEdit);
                    if (response) {
                        const dockArray = props.docks.filter((dock) => dock.id !== response?.id);
                        dockArray.push(new Dock(state.dockEdit));
                        context.emit('updateList', dockArray);
                        closeDockModal();
                    }
                }
            }
            state.saving = false;
        }

        async function deleteDock(dock: Dock) {
            const response = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.deletingADockDoorCannotBeUndone'),
            });
            if (!response) {
                return;
            }

            const deleted = await dockService.deleteDock(dock.id);
            if (deleted) {
                const dockArray = props.docks.filter((r) => r.id !== dock.id);
                context.emit('updateList', dockArray);
            }
        }

        function setDockType(dockType: DockType) {
            state.dockEdit.typeId = dockType.id;
        }

        return {
            state,
            table,
            openEditDockModal,
            saveDock,
            dockModal,
            closeDockModal,
            Permissions,
            deleteDock,
            validationResult,
            openAddDockModal,
            getDockTypeById,
            setDockType,
            getTitleCaseTranslation,
            getTranslation,
            pageTitle,
        };
    },
});
