import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import InventoryAverageDailyUsage from '@/modules/master-data/domain/InventoryAverageDailyUsage';
import UpdateInventoryAverageDailyUsageOverrideDTO from '@/modules/master-data/dtos/UpdateInventoryAverageDailyUsageOverrideDTO';

export default class InventoryApiService {
    public async getAverageDailyUsagesForLocation(locationId: number): Promise<DataAccessResponse<Array<InventoryAverageDailyUsage>>> {
        const response: AxiosResponse<DataAccessResponse<Array<InventoryAverageDailyUsage>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/inventory/average-daily-usages/${locationId}`,
        });
        return new DataListAccessResponseClass<InventoryAverageDailyUsage>(response, InventoryAverageDailyUsage).response;
    }

    public async updateAverageDailyUsageOverride(dto: UpdateInventoryAverageDailyUsageOverrideDTO): Promise<DataAccessResponse<InventoryAverageDailyUsage>> {
        const response: AxiosResponse<DataAccessResponse<InventoryAverageDailyUsage>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/inventory/average-daily-usage-override`,
            data: dto,
        });

        return new DataAccessResponseClass<InventoryAverageDailyUsage>(response, InventoryAverageDailyUsage).response;
    }
}
