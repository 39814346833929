
import {
    computed, defineComponent, nextTick, PropType, reactive,
} from 'vue';
import Location from '@/domain/Location';
import { StaticLocationType } from '@/domain/LocationType';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import { ValidationResult } from '@/validation/types';

type State = {
    show: boolean;
};

export default defineComponent({
    name: 'location-preferred-service-centers',
    props: {
        modelValue: {
            type: Object as PropType<Location>,
            required: true,
        },
        loading: Boolean,
        validationResult: {
            type: Object as PropType<ValidationResult<Location>>,
            required: true,
        },
        readonly: Boolean,
    },
    emits: ['update:modelValue'],
    setup(props) {
        const serviceCenters = masterDataStore.getInstance().locationStore.locations.filter((loc) => loc.type.staticTypeIdentifier === StaticLocationType.ServiceCenter);

        const state = reactive<State>({
            show: true,
        });

        const firstSelectOptions = computed(() => [{ id: 0, name: 'None' }, ...serviceCenters]);

        const secondSelectOptions = computed(() => firstSelectOptions.value.map((opt) => ({
            ...opt,
            disabled: opt.id > 0 && opt.id === props.modelValue.firstPreferredServiceCenter?.id,
        })));

        const thirdSelectOptions = computed(() => secondSelectOptions.value.map((opt) => ({
            ...opt,
            disabled: opt.id > 0 && (opt.id === props.modelValue.firstPreferredServiceCenter?.id || opt.id === props.modelValue.secondPreferredServiceCenter?.id),
        })));

        const disableSecondPreferredServiceCenter = computed((): boolean => !props.modelValue.firstPreferredServiceCenter?.id);

        const disableThirdPreferredServiceCenter = computed((): boolean => !props.modelValue.secondPreferredServiceCenter?.id);

        function changePreferredServiceCenter(location: Location, preference: 'first' | 'second' | 'third') {
            if (preference === 'first') {
                props.modelValue.firstPreferredServiceCenter = location;
                if (props.modelValue.firstPreferredServiceCenter.id === 0) {
                    delete props.modelValue.secondPreferredServiceCenter;
                    delete props.modelValue.thirdPreferredServiceCenter;
                }
            }
            if (preference === 'second') {
                props.modelValue.secondPreferredServiceCenter = location;
                if (props.modelValue.secondPreferredServiceCenter.id === 0) {
                    delete props.modelValue.thirdPreferredServiceCenter;
                }
            }
            if (preference === 'third') {
                props.modelValue.thirdPreferredServiceCenter = location;
            }
            state.show = false;
            nextTick(() => {
                state.show = true;
            });
        }

        return {
            changePreferredServiceCenter,
            secondSelectOptions,
            state,
            disableSecondPreferredServiceCenter,
            disableThirdPreferredServiceCenter,
            thirdSelectOptions,
            firstSelectOptions,
            getTitleCaseTranslation,
        };
    },
});
